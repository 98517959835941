import React, { Component, createContext } from 'react';
import { getMenu } from './actions/menu';
import { loginSuccess, logOut, loadUser, getId } from './actions/users';

const Context = createContext();

class GlobalContext extends Component {
   constructor(props) {
      super(props);
      this.state = {
         getMenu: getMenu.bind(this),
         loginSuccess: loginSuccess.bind(this),
         loadUser: loadUser.bind(this),
         logOut: logOut.bind(this),
         getId: getId.bind(this),
         getUser: this.getUser.bind(this),
         imagen_zoom: '',
         close_zoom: true,
         menu: [],
         modulos: [],
         permisos: [],
         Usuario: {},
         menuActivo: 0,
      };
   }

   componentDidMount() {
      this.getUser();
   }

   async getUser() {
      const token = await loadUser();
      this.setState({
         Usuario: {
            Usuario: token.Nombre,
            NombrePerfil: token.NombrePerfil,
            token: token.token,
            IdPersona: token.IdPersona,
         },
      });
   }

   render() {
      return (
         <Context.Provider value={this.state}>
            {this.props.children}
         </Context.Provider>
      );
   }
}

const Consumer = Component => {
   return props => {
      return (
         <Context.Consumer>
            {context => <Component {...props} context={context} />}
         </Context.Consumer>
      );
   };
};

export { Consumer, GlobalContext };
